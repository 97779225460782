body {
	font-family: 'Open Sans', sans-serif;
	color: #444;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

h1 {
	margin-top: 0rem;
	margin-bottom: 0.5rem;
	color: #0054a6;
	font-size: 2.25rem;
	line-height: 2.9rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #0054a6;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	color: #0054a6;
	font-size: 1.5rem;
	line-height: 1.975rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #0054a6;
	font-size: 1.25rem;
	line-height: 1.775rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
}

h5 {
	margin-top: 1.375rem;
	margin-bottom: 0.75rem;
	color: #0054a6;
	font-size: 0.9375rem;
	line-height: 1.5rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
}

h6 {
	margin-top: 1.5rem;
	margin-bottom: 0.875rem;
	color: #0054a6;
	font-size: 0.8125rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

p {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	color: #444;
	line-height: 1.5rem;
}

a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #3aa1af;
	line-height: 1.5rem;
	text-decoration: none;
}

a:hover {
	color: #28313b;
	text-decoration: none;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 1.25rem;
}

ol {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 1.125rem;
}

li {
	margin-bottom: 0.25rem;
}

label {
	display: block;
	margin-bottom: 0.25rem;
	line-height: 1.25rem;
	font-weight: 700;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02rem;
}

em {
	font-style: italic;
	letter-spacing: 0.02rem;
}

blockquote {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1.5rem 2rem;
	border-left: 4px solid rgba(0, 0, 0, 0.5);
	background-color: #ecf4f6;
	font-size: 1.125rem;
	line-height: 1.8rem;
}

.button {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1.5rem;
	border: 1px solid #196db6;
	background-color: #196db6;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	border-color: rgba(0, 84, 166, 0.15);
	background-color: #fff;
	color: #0054a6;
}

.button.secondary {
	border-color: #0054a6;
	background-color: #0054a6;
}

.button.secondary:hover {
	border-color: rgba(0, 84, 166, 0.15);
	background-color: #fff;
}

.button.tertiary {
	padding-right: 1rem;
	padding-left: 1rem;
	border-width: 2px;
	border-color: rgba(0, 84, 166, 0.15);
	background-color: #fff;
	color: #196db6;
	font-size: 0.85rem;
	line-height: 1.5em;
}

.button.tertiary:hover {
	border-color: transparent;
	background-color: #004180;
	color: #b2edff;
}

.button.right-sidebar-button {
	font-size: 1rem;
}

.button.header-search-button {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 2rem;
	height: 100%;
	min-height: 2rem;
	margin-top: 0em;
	margin-right: 0px;
	margin-bottom: 0em;
	padding: 0em 1.25rem;
	float: right;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border: 2px solid transparent;
	background-color: rgba(0, 0, 0, 0.25);
	background-image: url('/20170713122722/assets/images/search.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.8125rem;
	line-height: 1rem;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.3);
}

.button.slider-button {
	margin: 0.35rem 0rem 0.35rem 0px;
	padding: 0.35rem 0.85rem;
	float: left;
	border-color: transparent;
	background-color: #fff;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	color: #0054a6;
	font-size: 0.85rem;
	text-transform: uppercase;
}

.button.slider-button:hover {
	background-color: #196db6;
	color: #fff;
}

.container {
	display: block;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
}

.container.news {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	padding: 0rem 0rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	background-color: #fff;
}

.container.about-us {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	padding: 2rem 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
}

.container.products-and-contracts {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #196db6;
}

.container.inside-right {
	max-width: 1200px;
	padding-right: 2rem;
	padding-left: 2rem;
}

.nav-section {
	width: 100%;
	background-color: transparent;
}

.nav-container {
	position: relative;
	max-width: 100%;
}

.nav-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 44px;
	max-width: 100px;
	margin-right: 0px;
	padding: 0.8rem 3.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	background-color: transparent;
	color: #525f94;
	font-size: 0.85rem;
	line-height: 1.375em;
	font-weight: 600;
	text-align: center;
}

.nav-link:hover {
	background-color: rgba(32, 108, 119, 0.25);
}

.nav-link.w--current {
	padding: 0.75rem 1.15rem 0.6rem;
	border-bottom: 2px solid #3aa1af;
	background-color: #f0f5f6;
	box-shadow: inset 1px 0 0 0 #fff, inset -1px 0 0 0 #fff;
	color: #00214b;
	font-size: 0.85rem;
	font-weight: 600;
}

.nav-link.w--current:hover {
	border-bottom-color: #3aa1af;
	background-color: #ebf2f3;
	box-shadow: none;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 600px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-left: 1px none hsla(0, 0%, 50%, 0.25);
}

.logo-nittobo-link {
	width: 100%;
	border-bottom-style: none;
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
	-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
	border-bottom: 0px;
}

.logo-nittobo-link:hover {
	color: #fff;
}

.logo-nittobo-link.w--current {
	margin-top: 0rem;
	margin-bottom: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-section {
	position: relative;
	background-color: #002d54;
	background-image: -webkit-linear-gradient(270deg, rgba(0, 73, 134, 0.75), rgba(0, 73, 134, 0.75));
	background-image: linear-gradient(180deg, rgba(0, 73, 134, 0.75), rgba(0, 73, 134, 0.75));
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.row.footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.column {
	width: 50%;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	float: left;
}

.column.in-thirds {
	width: 50%;
	padding-top: 0.5rem;
}

.column.signoff {
	padding-right: 0rem;
	padding-left: 0rem;
}

.column.full {
	width: 100%;
	padding-top: 0.5rem;
}

.column.half {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 0.5rem;
	padding-right: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.column.half._1 {
	min-height: 350px;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.column.half._2 {
	min-height: 350px;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.column.image-half {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 0.5rem;
	padding-right: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.column.image-half._1 {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.column.image-half._2 {
	min-height: 350px;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background-color: rgba(0, 73, 134, 0.5);
	background-position: 50% 100%;
	background-size: cover;
	background-repeat: no-repeat;
}

.column.image-half {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 0.5rem;
	padding-right: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.column.image-half._1 {
	min-height: 350px;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background-position: 50% 100%;
	background-size: cover;
	background-repeat: no-repeat;
}

.column.image-half._2 {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background-color: rgba(0, 73, 134, 0.5);
}

.logo-image {
	width: 250px;
	-webkit-transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.footer-signoff-section {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	background-color: #002d54;
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	width: 100%;
	margin-right: 0.75rem;
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 0rem;
	color: #c0c8cf;
	font-size: 0.8rem;
}

.footer-signoff-list {
	display: inline-block;
	width: 100%;
	margin-top: 0rem;
	margin-right: 0.25rem;
	margin-bottom: 0rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0rem;
	padding-right: 0.5rem;
	padding-left: 0rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	padding-left: 32px;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	background-image: url('/20170713122722/assets/images/globalreach_flat_blue.png');
	background-size: 130px 28px;
	background-repeat: no-repeat;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-size: 8px;
	line-height: 9px;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #606060;
}

.footer-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 42%;
	max-width: 50%;
	clear: both;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.footer-column-wrap._2 {
	width: 29%;
}

.footer-column-wrap._3 {
	width: 29%;
}

.footer-column-title {
	margin-top: 0.5em;
	color: #fff;
	font-size: 1.125rem;
}

.header-content-right-wrap {
	display: none;
	margin-right: 1em;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.header-contents-wrap {
	position: fixed;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 120px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(0, 0%, 100%, 0.8);
	-webkit-transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0em 0.125rem 1em;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	height: 100%;
	float: left;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75rem;
	padding: 4px 10px;
	font-size: 0.875rem;
}

.form-field.right-sidebar-field {
	width: 100%;
	margin-top: 0.375em;
	margin-bottom: 1.25em;
	padding-right: 4px;
	padding-left: 8px;
}

.form-field.right-sidebar-field.miles-from {
	width: 52%;
}

.form-field.right-sidebar-field.zip-code {
	width: 45%;
}

.form-field.header-search-field {
	width: 2rem;
	height: 2.25rem;
	margin-bottom: 0em;
	padding: 0.5rem 1rem;
	float: left;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	border: 0px none transparent;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	background-image: -webkit-linear-gradient(206deg, #1b2f63, #3aa1af);
	background-image: linear-gradient(244deg, #1b2f63, #3aa1af);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff;
	font-size: 0.75em;
}

.form-field.header-search-field:focus {
	border: 0px none transparent;
	background-color: rgba(0, 0, 0, 0.3);
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #079429;
	color: #fff;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #c23737;
	color: #fff;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-bottom: 0em;
	float: left;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	letter-spacing: 0.01em;
}

.secondary-nav-list-item.first {
	border-left-style: none;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-nav-column-div {
	padding-left: 0px;
}

.main-content-column.inside-right {
	padding-right: 0px;
	padding-left: 0px;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
}

.left-nav-list-item {
	margin-bottom: 1px;
}

.left-nav-list-link {
	display: block;
	padding: 0.75em 1.5rem 0.75em 0.9rem;
	border-bottom: 1px solid rgba(0, 99, 183, 0.08);
	background-image: url('/20170713122722/assets/images/nav-arrow_white-01.svg');
	background-position: 0px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	color: #1b2f33;
	font-weight: 600;
}

.left-nav-list-link:hover {
	background-image: url('/20170713122722/assets/images/nav-arrow_blue-01.svg');
	background-position: 0px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	color: #196db6;
	border-bottom: 1px solid rgba(0, 99, 183, 0.08);
}

.left-nav-list-link.w--current {
	background-image: url('/20170713122722/assets/images/nav-arrow_blue-01.svg');
	background-size: 11px 11px;
	color: #0054a6;
}

.left-nav-list-link.section-title {
	border-bottom-style: none;
	background-color: #0063b7;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1em;
	text-transform: uppercase;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem 0.75em 1.75rem;
	border-bottom: 1px solid rgba(0, 99, 183, 0.08);
	background-image: url('/20170713122722/assets/images/nav-arrow_white-01.svg');
	background-position: 12px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	color: #1b2f33;
	font-size: 0.8125em;
	line-height: 1.375em;
	font-weight: 600;
}

.left-nav-nested-list-link:hover {
	background-image: url('/20170713122722/assets/images/nav-arrow_blue-01.svg');
	background-position: 12px 50%;
	background-size: 11px 11px;
	color: #196db6;
	border-bottom: 1px solid rgba(0, 99, 183, 0.08);
}

.left-nav-nested-list-link.w--current {
	background-color: #fff;
	background-image: url('/20170713122722/assets/images/nav-arrow_blue-01.svg');
	background-size: 11px 11px;
	color: #0054a6;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	background-position: 24px 50%;
}

.left-nav-nested-list-link.great-grandchild:hover {
	color: #196db6;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.sidebar-section-title {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.775rem;
	text-transform: none;
}

.sidebar-list {
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	padding-top: 10rem;
	background-color: #f0f0f0;
}

.secondary-nav-icon {
	width: 1.375em;
	height: 1.375em;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.secondary-nav-text {
	float: left;
}

.secondary-nav-text.indicator-text {
	position: relative;
	margin-left: 0.25rem;
	padding: 3px 5px;
	border-radius: 2px;
	background-color: rgba(33, 41, 49, 0.27);
	font-size: 0.5625rem;
	line-height: 1.25em;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0em;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	color: #fff;
}

.slider-section {
	position: relative;
	background-color: #373a3d;
}

.slider {
	display: block;
	height: 100vh;
	max-width: 100%;
	min-height: 500px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-arrow {
	opacity: 0.55;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 2rem;
}

.slider-arrow:hover {
	opacity: 1;
}

.slider-indicator {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 3rem;
	margin-right: 5rem;
	margin-bottom: 6rem;
	margin-left: 5rem;
	padding-top: 5px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.65rem;
}

.home-section {
	position: relative;
	z-index: 1001;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	overflow: hidden;
}

.home-content-box-wrap {
	width: 100%;
}

.home-content-box-wrap.products {
	width: 80%;
}

.home-content-box-wrap.contract {
	width: 80%;
}

.home-content-box-title {
	margin-top: 0rem;
	margin-bottom: 0.65rem;
	font-size: 1.375em;
	line-height: 1.5em;
}

.home-cbox-learn-more {
	display: inline-block;
	margin-top: 0.5rem;
	clear: both;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	border-bottom: 2px solid hsla(93, 16%, 68%, 0.31);
	color: #abba9f;
	font-size: 1rem;
	font-weight: 600;
}

.home-cbox-learn-more:hover {
	color: #0054a6;
	border-bottom: 2px solid hsla(93, 16%, 68%, 0.31);
}

.home-cbox-learn-more.light {
	border-bottom-color: hsla(0, 0%, 100%, 0.31);
	color: #fff;
}

.home-cbox-learn-more.light:hover {
	border-bottom-color: transparent;
	color: #fff;
}

.slider-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	color: #fff;
}

.slider-image._1 {
	border-bottom: 6px none #3aa1af;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-image._2 {
	border-bottom: 6px none #3aa1af;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-image._3 {
	border-bottom: 6px none #3aa1af;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-caption-wrap {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1700px;
	min-width: 550px;
	margin-right: auto;
	margin-bottom: 9rem;
	margin-left: auto;
	padding: 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: hsla(0, 0%, 100%, 0.48);
	color: #fff;
	text-align: center;
}

.inside-page-header-content-wrap {
	margin-bottom: 1.5rem;
	padding-bottom: 0.5em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.inside-content-beckground {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 3rem;
	padding-bottom: 4rem;
	border-top: 4px solid #196db6;
	background-color: #fff;
	min-height: 500px;
}

.header-menu-inside-wrap {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.intro-text-style {
	font-size: 1.1875em;
	line-height: 1.5em;
}

.slider-arrow-icon {
	color: #fff;
}

.wide-background-block {
	display: block;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}

.wide-background-block.content-box-background {
	max-width: 100%;
	border-bottom: 3px solid #eaecec;
	background-color: hsla(180, 5%, 92%, 0.5);
	box-shadow: none;
	color: #fff;
}

.slider-caption-title {
	display: block;
	width: 50%;
	margin: 0rem auto 0.35rem;
	color: #002d54;
	line-height: 2.25rem;
	text-align: center;
}

.footer-links-list {
	margin-top: -0.25em;
	border-top: 1px none hsla(0, 0%, 100%, 0.1);
}

.footer-links-list-item {
	margin-bottom: 1px;
	box-shadow: none;
}

.home-cbox-date {
	width: 100%;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	padding-left: 1.5rem;
	background-image: url('/20170713122722/assets/images/calendar-icon-01.svg');
	background-position: 0px 50%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
	color: #1b2f63;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: -1.5rem;
	margin-right: 0.5em;
	padding-bottom: 1.5em;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.footer-link {
	color: #fff;
	font-size: 0.875rem;
}

.footer-link:hover {
	color: #bef2ff;
}

.footer-link.footer-list-item-link {
	display: block;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
	color: #fff;
	font-size: 0.875rem;
}

.footer-link.footer-list-item-link:hover {
	color: #bef2ff;
}

.footer-link.signoff {
	font-size: 0.8rem;
}

.link {
	border-bottom: 1px solid rgba(0, 185, 242, 0.25);
	color: #0054a6;
	font-size: 1rem;
}

.link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #196db6;
}


.link.pagination-link {
	margin: 0.5em 0.25em;
	float: left;
	font-weight: 700;
	text-transform: uppercase;
}

.link.pagination-link.next {
	float: right;
}

.link.h3-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #196db6;
	font-size: 1.5rem;
	line-height: 1.975rem;
}

.link.h3-link:hover {
	color: #0054a6;
}

.link.content-box-title-link {
	color: #196db6;
	font-size: 1.5rem;
	font-weight: 600;
	border-bottom: 0px;
	line-height: 1.7rem;
}

.link.content-box-title-link:hover {
	border-bottom: 0px;
	color: #799165;
}

.link.content-box-title-link a {
	border-bottom: 0px;
}

.link.content-box-title-link a:hover {
	border-bottom: 1px solid rgba(139, 184, 222, 0.65);
	color: #799165;
}

.link.content-box-title-link.light {
	color: #fff;
}

.link.content-box-title-link.light:hover {
	border-bottom-width: 2px;
	border-bottom-color: hsla(0, 0%, 100%, 0.25);
}

.link.h2-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #196db6;
	font-size: 1.875rem;
	line-height: 2.175rem;
}

.link.h2-link:hover {
	color: #0054a6;
}

.link.breadcrumb-link {
	color: #799165;
	font-size: 0.6875rem;
	line-height: 0.6874rem;
}

.link.h4-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #196db6;
	font-size: 1.25rem;
	line-height: 1.775rem;
}

.link.h4-link:hover {
	color: #0054a6;
}

.link.h5-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #196db6;
}

.link.h5-link:hover {
	color: #0054a6;
}

.link.h6-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #196db6;
	font-size: 0.8125rem;
	line-height: 1.25rem;
}

.link.h6-link:hover {
	color: #0054a6;
}

.link.h1-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #196db6;
	font-size: 2.25rem;
	line-height: 2.9rem;
}

.link.h1-link:hover {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #0054a6;
}

.pagination-middle-wrap {
	display: inline-block;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.pagination-middle-text {
	display: inline-block;
	margin-right: 0.5em;
	margin-left: 0.5em;
}

.pagination-middle-select {
	display: inline-block;
	width: auto;
	max-height: 2.25rem;
	margin-bottom: 0px;
	padding: 0px 0px 0px 2px;
}

.pagination-wrap {
	margin-bottom: 1.5em;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-size: 0.875em;
	line-height: 1.25em;
	text-align: center;
}

.md-doctor-form-reveal-wrap {
	display: none;
	overflow: hidden;
}

.md-advanced-search-tooltip-popup {
	position: absolute;
	left: -1rem;
	bottom: 100%;
	display: none;
	width: 155px;
	margin-bottom: 0.5rem;
	padding: 0.75rem 1.125rem 1.125rem 0.875rem;
	border-radius: 3px;
	background-color: #28313b;
	box-shadow: 0 0 20px 5px rgba(40, 49, 59, 0.25);
	color: #fff;
	font-size: 0.8125em;
	line-height: 1.375em;
}

.md-patient-comments-reveal-more {
	overflow: hidden;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.loc-view-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-view-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 48%;
	padding-top: 0.5em;
	padding-right: 0.25em;
	padding-bottom: 0.5em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: solid;
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	opacity: 0.55;
	-webkit-transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	color: #000;
	font-weight: 700;
}

.loc-view-button-wrap:hover {
	border-color: rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.loc-view-button-wrap.w--current {
	border-color: #000;
	opacity: 0.7;
}

.loc-view-button-wrap.w--current:hover {
	opacity: 1;
}

.loc-miles-from-zip-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-list-section-title {
	margin-top: 0em;
}

.loc-select-type-form-wrap {
	position: relative;
}

.loc-type-form {
	position: relative;
}

.loc-type-form-checkbox {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-bottom: 0.75em;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	line-height: 1.375em;
}

.loc-type-form-checkbox.select-all-wrap {
	font-weight: 700;
}

.loc-type-form-checkbox.sidebar-checkbox {
	width: 100%;
	margin-bottom: 0.5em;
}

.loc-type-form-checkbox.sidebar-checkbox.select-all {
	margin-top: 0.75em;
	font-weight: 600;
}

.loc-type-form-checkbox-box {
	position: relative;
	width: 1.5em;
	height: 1.5em;
	margin-top: 0px;
	margin-right: 0.5em;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.loc-type-form-checkbox-box.select-all-checkbox {
	width: 1.4125em;
	height: 1.4125em;
	margin-right: 0.5em;
}

.loc-type-form-checkbox-label {
	position: relative;
	float: none;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.loc-type-form-checkbox-label.select-all-label {
	font-weight: 700;
	text-transform: uppercase;
}

.loc-type-form-checkboxes-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1.25em;
	padding-top: 1em;
	padding-bottom: 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.loc-type-form-checkbox-image {
	position: relative;
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.5em;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-sidebar-search-reveal-button {
	position: relative;
	display: none;
	margin-bottom: 0em;
	padding: 0.75em 0.5em 0.75em 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	font-size: 1.125em;
	line-height: 1.25em;
	font-weight: 600;
	cursor: pointer;
}

.loc-sidebar-search-reveal-button:active {
	color: #777;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.right-sidebar-refine-search-button-text {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: 0.25em;
	font-size: 1.125em;
	line-height: 1.25em;
}

.loc-refine-search-checkbox-wrap {
	padding-top: 0.5em;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-view-button-text {
	margin-left: 0.25em;
}

.loc-view-button-image {
	position: relative;
	display: block;
	width: 1.125rem;
	height: 1.375rem;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 3%;
	margin-left: 3%;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px none hsla(0, 0%, 50%, 0.25);
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	border-bottom: 4px none transparent;
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle:hover {
	border-bottom-width: 4px;
	border-bottom-color: #196db6;
	background-color: hsla(0, 0%, 100%, 0.9);
	background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, #fff, hsla(191, 6%, 61%, 0.08) 99%);
	background-image: radial-gradient(circle farthest-corner at 50% 50%, #fff, hsla(191, 6%, 61%, 0.08) 99%);
	color: hsla(0, 0%, 65%, 0.8);
}

.nav-drop-toggle.w--open {
	color: #fff;
}

.nav-drop-list {
	display: none;
	width: 200px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.nav-drop-list.w--open {
	left: 0px;
	top: 100%;
	display: block;
	max-width: 1500px;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: transparent;
	color: #fff;
}

.nav-drop-list-link {
	display: block;
	width: 100%;
	margin-right: 0px;
	margin-bottom: 0.35rem;
	margin-left: 0px;
	padding: 0.35em 0.75em 0.35em 0.75rem;
	border: 2px solid transparent;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
	white-space: normal;
}

.nav-drop-list-link:hover {
	background-color: transparent;
	color: #fff;
	text-shadow: 0 1px 31px #000;
}

.nav-drop-list-link.w--current {
	background-color: rgba(0, 84, 166, 0.85);
	color: #fff;
}

.nav-drop-list-link.w--current:hover {
	text-shadow: none;
}

.nav-drop-toggle-link {
	width: 100%;
	padding: 0.75rem 1.15rem;
	border-bottom: 4px solid transparent;
	color: #1b2f33;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
}

.nav-drop-toggle-link:hover {
	border-bottom-color: #196db6;
	background-color: transparent;
	color: #00214b;
}

.nav-drop-toggle-link.w--current {
	border-right: 1px solid transparent;
	border-bottom: 4px solid #0054a6;
	border-left: 1px solid transparent;
	background-color: transparent;
	color: #1b2f33;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	height: 100%;
	max-width: 1700px;
	min-width: 950px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.secondary-header-item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 1.5rem;
	line-height: 1.5em;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.secondary-header-item-cta-link {
	margin-left: 1rem;
	padding: 0.75em 3em 0.75em 1.5em;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	background-color: rgba(0, 0, 0, 0.25);
	background-image: url('/20170713122722/assets/images/keyboard-right-arrow-button-1.svg');
	background-position: 85% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.secondary-header-item-cta-link:hover {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
}

.header-search-wrap {
	display: none;
	margin-top: 1rem;
	margin-bottom: 1rem;
	float: right;
}

.header-search-form {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: hsla(0, 0%, 100%, 0.18);
}

.slider-caption-title-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0.35rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #28313b;
}

.nav-drop-list-inside-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.5rem;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.footer-contact-label {
	display: inline-block;
	min-width: 64px;
}

.ordered-list.alpha-order {
	list-style-type: lower-alpha;
}

.ordered-list.roman-order {
	list-style-type: lower-roman;
}

.nav-drop-list-new {
	overflow: hidden;
}

.nav-drop-list-new.w--open {
	left: 0px;
	top: 100%;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #000;
	color: #fff;
}

.nav-drop-list-element {
	position: absolute;
	left: 0%;
	top: 100%;
	z-index: 3;
	display: none;
	overflow: hidden;
	width: 100%;
	min-width: 200px;
	margin: 0px;
	padding: 0.5rem;
	background-color: #000;
	box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.25);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
}

.nav-drop-background {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: none;
	background-color: rgba(0, 0, 0, 0.25);
}

.secondary-header-item-text {
	float: left;
}

.secondary-header-item-icon {
	width: 1em;
	height: 1em;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.footer-container {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.main-content-wrapper {
	display: block;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.main-content-wrapper.inside-left {
	padding-right: 2rem;
	padding-left: 2rem;
}

.div-block {
	display: none;
}

.main-content-container {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.main-content-container.left-right {
	padding-right: 2rem;
	padding-left: 2rem;
}

.text-block {
	color: #acacac;
}

.nav-dropdown-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: #142336;
}

.patient-portal-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 130px;
	height: 31px;
	margin-top: 0.45rem;
	margin-bottom: 0.45rem;
	padding: 0.3rem 2rem 0.3rem 0.65rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid transparent;
	border-radius: 3px;
	background-color: #00214b;
	background-image: url('/20170713122722/assets/images/patient_portal_secure_icon.svg');
	background-position: 92% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1.25rem;
	text-align: center;
}

.patient-portal-button:hover {
	border: 2px solid #54c4c4;
	background-color: transparent;
	background-image: url('/20170713122722/assets/images/patient_portal_secure_icon_hover-07.svg');
	background-position: 92% 50%;
	background-size: 12px;
	color: #fff;
}

.slide-caption-text {
	width: 50%;
	max-width: 525px;
	min-width: 525px;
	color: #1b2f33;
	line-height: 1.45rem;
	font-weight: 600;
	text-align: center;
}

.paragraph {
	color: #fff;
}

.paragraph-2 {
	color: #fff;
}

.breadcrumb-current-page {
	line-height: 0.6875rem;
}

.search-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
}

.search-input {
	display: none;
}

.search-button {
	height: 100%;
	min-height: 40px;
	min-width: 40px;
	margin-right: 1rem;
	padding: 0.55rem 1.4rem;
	background-color: #586879;
	background-image: url('/20170713122722/assets/images/search.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
}

.search-button:hover {
	background-color: #838e99;
}

.main-nav-section {
	position: static;
	left: 0px;
	top: 0px;
	right: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.chinese-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	box-shadow: none;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #0054a6;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: center;
	text-transform: none;
}

.chinese-button:hover {
	background-color: #196db6;
	color: #fff;
}

.search-hidden-dropdown {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: hsla(0, 0%, 100%, 0.2);
}

.search-form-wrapper {
	width: 80%;
	max-width: 1700px;
	min-width: 991px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0rem;
	padding-left: 0rem;
}

.search-field-div {
	width: 90%;
	height: 100%;
	min-height: 50px;
	margin-bottom: 0px;
	padding-right: 2rem;
	padding-left: 2rem;
	float: left;
	border-style: none;
	background-color: rgba(0, 0, 0, 0.08);
}

.search-field-div::-webkit-input-placeholder {
	color: #28313b;
}

.search-field-div:-ms-input-placeholder {
	color: #28313b;
}

.search-field-div::placeholder {
	color: #28313b;
}

.search-form-block {
	display: none;
	margin-bottom: 0px;
	background-color: #f1f1f1;
	opacity: 0;
}

.search-button-hidden {
	display: block;
	width: 10%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 0.8rem 0rem;
	float: left;
	background-color: #277f8b;
	color: #fff;
	font-size: 0.95rem;
	font-weight: 400;
	letter-spacing: 0.08rem;
	text-transform: none;
}

.search-button-hidden:hover {
	background-color: #586879;
}

.search-wrap-new {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
}

.navigation-section {
	position: fixed;
	z-index: 100000000000;
	width: 100%;
	height: 120px;
	min-height: 55px;
	background-color: transparent;
	box-shadow: 0 3px 28px -14px rgba(0, 0, 0, 0.5);
	-webkit-transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.logo-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.chinese-button-wrap {
	position: absolute;
	top: 0px;
	right: 0px;
	width: auto;
	min-width: 4rem;
}

.caption-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.75rem 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(0, 0%, 100%, 0.4);
}

.products-paragraph {
	color: #fff;
}

.contracts-paragraph {
	color: #fff;
}

.events-news-haeding {
	width: 100%;
	padding: 2rem 10% 2rem 10.5%;
	border-bottom: 4px solid #f1f4ee;
	background-color: #fff;
	color: #002d54;
	text-align: left;
}

.html-embed-2 {
	display: none;
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.nittobo-footer-logo-image {
	width: 250px;
}

.arrow-div {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(0, 0%, 100%, 0.25);
}

.arrow-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.arrow-link {
	position: relative;
	z-index: 2;
	width: 71px;
	height: 70px;
	background-color: hsla(0, 0%, 100%, 0.74);
	background-image: url('/20170713122722/assets/images/bt-blue-down-arrow.svg');
	background-position: 50% 50%;
	background-size: 40px 40px;
	background-repeat: no-repeat;
	color: #196db6;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.arrow-link:hover {
	background-color: #196db6;
	background-image: url('/20170713122722/assets/images/white-down-arrow.svg');
	background-size: 40px 40px;
}

.block-quote {
	border-left-color: #196db6;
	background-color: #f8f8f8;
	color: #0054a6;
	font-size: 1.15rem;
	line-height: 1.85rem;
	font-weight: 600;
}

.hr-div {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid hsla(0, 0%, 56%, 0.25);
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000011;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="new-nav-drop-list"] {
	height: 0px;
}

html.w-mod-js *[data-ix="slider-upward-shift"] {
	opacity: 1;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.75rem;
	}

	.button.header-search-button {
		width: 5rem;
		min-height: 4rem;
		font-size: 1.125rem;
	}

	.button.slider-button {
		margin-right: 0rem;
		margin-left: 0rem;
		border-style: solid;
		border-width: 1px;
		border-color: hsla(93, 16%, 68%, 0.45);
	}

	.button.slider-button:hover {
		border-style: solid;
		border-color: transparent;
	}

	.container {
		max-width: 100%;
		padding-right: 20px;
		padding-left: 20px;
	}

	.container.news {
		width: 100%;
		max-width: 991px;
		min-width: 767px;
	}

	.container.about-us {
		width: 100%;
		max-width: 991px;
		min-width: 767px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.products-and-contracts {
		width: 100%;
		max-width: 991px;
		min-width: 767px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.container.inside-right {
		padding-right: 1.5rem;
		padding-bottom: 1.5px;
		padding-left: 1.5rem;
	}

	.nav-container {
		width: 96px;
		height: 110px;
		display: inline-block;
		float: none;
		position: absolute;
		right: 0;
	}

	.nav-link {
		display: block;
		width: 100%;
		height: auto;
		min-width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		margin-left: 0px;
		padding: 0.75rem 1rem;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
		background-color: transparent;
		box-shadow: none;
		color: #fff;
		line-height: 1.375rem;
		font-weight: 600;
		text-align: left;
		text-transform: capitalize;
	}

	.nav-link:hover {
		background-color: #0054a6;
		box-shadow: inset 4px 0 0 0 #196db6;
		color: #fff;
	}

	.nav-link.w--current {
		height: auto;
		margin-left: 0px;
		background-color: rgba(39, 127, 139, 0.15);
		box-shadow: none;
		color: #fff;
	}

	.nav-link.w--current:hover {
		background-color: rgba(131, 142, 153, 0.2);
		box-shadow: none;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.15);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-menu-wrap {
		display: block;
		width: auto;
		min-width: 500px;
		margin-top: 0rem;
		padding-right: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		background-color: #002d54;
		text-align: left;
	}

	.logo-nittobo-link {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
		font-size: 1.375rem;
	}

	.logo-nittobo-link.w--current {
		z-index: 1;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 100%;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
		margin-left: 0rem;
		padding-bottom: 0px;
		padding-left: 0rem;
	}

	.row {
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.row.footer {
		width: 100%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.column {
		text-align: left;
	}

	.column.half._1 {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.column.half._2 {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.column.image-half._2 {
		background-position: 50% 50%;
		background-size: cover;
	}

	.column.footer {
		text-align: right;
	}

	.menu-button {
		display: block;
		width: 3rem;
		height: 100%;
		min-height: 100%;
		min-width: 3rem;
		margin-right: auto;
		margin-left: auto;
		padding: 0px 0rem;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
		color: rgba(0, 0, 0, 0.4);
		font-size: 1em;
		line-height: 3.5rem;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.footer-signoff-section {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		text-align: center;
	}

	.copyright-text {
		margin-bottom: 0.25rem;
		text-align: left;
	}

	.footer-signoff-list {
		text-align: left;
	}

	.footer-signoff-grip {
		margin-top: 0px;
		float: none;
	}

	.footer-column-wrap {
		max-width: 57%;
	}

	.header-content-right-wrap {
		margin-right: 0.75em;
		padding-bottom: 0rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
	}

	.header-contents-wrap {
		display: block;
		width: 100%;
		height: 110px;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		background-color: hsla(0, 0%, 100%, 0.85);
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.header-menu-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 10%;
		height: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.form-field.right-sidebar-field {
		margin-bottom: 1.5em;
	}

	.form-field.right-sidebar-field.miles-from {
		width: 100%;
		margin-bottom: 0.25em;
	}

	.form-field.right-sidebar-field.zip-code {
		width: 100%;
	}

	.form-field.header-search-field {
		width: 100%;
		height: 4rem;
		margin-bottom: 0rem;
		padding-left: 3rem;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		font-size: 0.9375rem;
		line-height: 1.25em;
	}

	.form-field.header-search-field:focus {
		width: 100%;
	}

	.form-field.header-search-field::-webkit-input-placeholder {
		color: #fff;
	}

	.form-field.header-search-field:-ms-input-placeholder {
		color: #fff;
	}

	.form-field.header-search-field::placeholder {
		color: #fff;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		border-left: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
		border-top: 2px solid rgba(0, 84, 166, 0.15);
	}

	.right-sidebar-column.inside-right {
		padding-right: 0px;
		padding-left: 0px;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.section-wrap.inside-content-section {
		padding-top: 7.75rem;
	}

	.secondary-nav-icon {
		position: relative;
		z-index: 2;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.secondary-nav-text {
		margin-right: 0.25em;
		margin-left: 0.25em;
	}

	.secondary-nav-text.indicator-text {
		position: absolute;
		left: 1.25em;
		top: 50%;
		display: none;
		margin-right: 0em;
		margin-left: 0rem;
		float: none;
		font-size: 0.75em;
		line-height: 1.25em;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 2em;
		padding-left: 2em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75rem;
		line-height: 1.25em;
		font-weight: 600;
		cursor: pointer;
	}

	.slider-section {
		background-color: #f1f4ee;
	}

	.slider {
		height: 775px;
	}

	.slider-arrow {
		top: 9rem;
		height: 9%;
		font-size: 1.75rem;
	}

	.slider-indicator {
		width: 100%;
		margin-right: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		padding-top: 1.1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: hsla(191, 6%, 61%, 0.15);
	}

	.home-section {
		padding-top: 0rem;
		overflow: hidden;
	}

	.home-content-box-wrap {
		width: 100%;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		float: left;
	}

	.home-content-box-wrap._1 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.home-content-box-wrap.products {
		width: 100%;
	}

	.home-content-box-wrap.contract {
		width: 100%;
	}

	.slider-image._1 {
		height: 500px;
		background-position: 50% 50%;
	}

	.slider-image._2 {
		height: 500px;
	}

	.slider-image._3 {
		height: 500px;
		background-size: 1000px;
		background-repeat: no-repeat;
	}

	.slider-caption-wrap {
		position: absolute;
		left: 0px;
		top: auto;
		right: 0px;
		bottom: -55px;
		width: 100%;
		height: 221px;
		max-width: 100%;
		margin-bottom: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
		background-color: transparent;
		background-image: -webkit-linear-gradient(90deg, transparent, #fff);
		background-image: linear-gradient(0deg, transparent, #fff);
		-webkit-transform: translate(0px, -50%);
		-ms-transform: translate(0px, -50%);
		transform: translate(0px, -50%);
		line-height: 1.6rem;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.menu-button-icon {
		display: inline-block;
		width: 100%;
		height: 100%;
		min-height: 50px;
		min-width: 30px;
		margin-right: auto;
		margin-left: auto;
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		color: #47682c;
		font-size: 2rem;
		line-height: 4rem;
	}

	.menu-button-icon:hover {
		color: #799165;
	}

	.menu-button-text {
		display: none;
		float: left;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.7rem;
		line-height: 4rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		width: 100%;
		height: 100%;
		max-width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.home-cbox-text-wrap {
		width: 100%;
		padding-top: 0rem;
		float: right;
	}

	.slider-arrow-icon {
		top: 0%;
	}

	.wide-background-block.content-box-background {
		padding-bottom: 2rem;
	}

	.slider-caption-title {
		font-size: 1.5rem;
		line-height: 2rem;
		letter-spacing: 0.01rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.link.content-box-title-link {
		color: #196db6;
	}

	.md-doctor-form-reveal-wrap {
		display: none;
	}

	.left-nav-reveal-button {
		display: block;
		margin-bottom: 1.25em;
		padding: 1em 50px;
		background-color: #5b636b;
		background-image: url('/20170713122722/assets/images/menu_hamburger_stagger_wht.svg');
		background-position: 17px 50%;
		background-size: 19px;
		background-repeat: no-repeat;
		color: #b8c5d1;
		letter-spacing: 0.05em;
	}

	.left-nav-reveal-button:hover {
		color: #fff;
	}

	.left-nav-reveal-button:active {
		background-color: #50575e;
	}

	.loc-miles-from-zip-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 33.33%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 33.33%;
		margin-bottom: 0.75em;
	}

	.loc-type-form-checkbox.sidebar-checkbox.select-all {
		width: 100%;
		margin-top: 0.75em;
	}

	.loc-type-form-checkboxes-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.loc-sidebar-search-reveal-button {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.loc-refine-search-checkbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0.75em;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		border-bottom-style: none;
		box-shadow: inset -4px 0 0 0 transparent;
	}

	.nav-drop-toggle:hover {
		border-bottom-style: none;
		box-shadow: inset -4px 0 0 0 transparent;
	}

	.nav-drop-toggle-link {
		display: block;
		width: 90%;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 1rem;
		float: left;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		color: #fff;
		font-weight: 400;
		text-align: left;
	}

	.nav-drop-toggle-link:hover {
		background-color: #0054a6;
		box-shadow: inset 4px 0 0 0 #196db6;
		color: #fff;
	}

	.nav-drop-toggle-link.w--current {
		border-bottom-width: 1px;
		border-bottom-color: rgba(140, 205, 214, 0.1);
		background-color: #0054a6;
		box-shadow: inset 3px 0 0 0 #196db6;
		color: #fff;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/20170713122722/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 0.875rem;
		line-height: 1.875rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #28313b;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('/20170713122722/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 110px;
		max-width: none;
		min-width: auto;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-left: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.secondary-header-item-wrap {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		font-size: 1.5rem;
	}

	.secondary-header-item-cta-link {
		margin-right: 1rem;
		margin-left: 1rem;
		font-size: 0.8125rem;
	}

	.header-search-wrap {
		width: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		padding: 0rem;
		float: none;
	}

	.header-search-form {
		width: 100%;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.slider-caption-title-wrap {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.mobile-search-close-icon {
		position: relative;
		z-index: 2;
		display: none;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		padding: 1px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.mobile-search-active-bknd {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		z-index: 1;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.1);
	}

	.secondary-header-item-icon {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-wrapper.inside-left {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-container.left-right {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.patient-portal-button {
		margin-top: 0.75rem;
	}

	.slide-caption-text {
		width: 75%;
	}

	.search-wrap {
		width: 100%;
	}

	.search-input {
		display: none;
		width: 100%;
		height: 46px;
		margin-bottom: 0px;
		padding-left: 2rem;
		float: left;
		background-color: #eee;
	}

	.search-input:focus {
		background-color: rgba(190, 242, 255, 0.2);
	}

	.search-input::-webkit-input-placeholder {
		color: #1b2f63;
	}

	.search-input:-ms-input-placeholder {
		color: #1b2f63;
	}

	.search-input::placeholder {
		color: #1b2f63;
	}

	.search-button {
		width: 10%;
		height: 46px;
		max-width: 50px;
		margin-right: 0rem;
		background-color: #586879;
	}

	.search-button:hover {
		background-color: #838e99;
	}

	.chinese-button {
		margin-right: 0px;
		margin-bottom: 0rem;
	}

	.search-form-wrapper {
		width: 100%;
		min-width: 768px;
	}

	.search-field-div {
		width: 86%;
	}

	.search-button-hidden {
		width: 14%;
		min-width: 100px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.search-wrap-new {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 45px;
		margin-right: 2rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.link-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 10%;
		height: 45px;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
	}

	.link-arrow-image {
		width: 100%;
		height: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
		border-left: 1px solid rgba(140, 205, 214, 0.1);
	}

	.link-arrow-image:hover {
		background-color: #0054a6;
	}

	.navigation-section {
		height: 110px;
	}

	.logo-div {
		width: 45%;
	}

	.chinese-button-wrap {
		top: 0px;
		right: 0px;
		width: 6rem;
	}

	.caption-container {
		width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		background-color: transparent;
	}

	.events-news-haeding {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.nittobo-footer-logo-image {
		max-width: 275px;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 1.6875rem;
		line-height: 1.3125em;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 1.4375em;
	}

	h3 {
		font-size: 1.3125rem;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}

	.button.header-search-button {
		min-height: 3rem;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.news {
		max-width: none;
		min-width: auto;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.about-us {
		max-width: none;
		min-width: auto;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container.products-and-contracts {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		max-width: none;
		min-width: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.styles {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.nav-section {
		height: auto;
		min-height: auto;
	}

	.nav-menu-wrap {
		min-width: 450px;
	}

	.logo-nittobo-link {
		width: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-left: 1.5rem;
	}

	.logo-nittobo-link.w--current {
		width: 100%;
		margin-top: 0em;
		margin-bottom: 0em;
		margin-left: 1.5rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.row {
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.row.footer {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.news {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column {
		width: 100%;
	}

	.column.in-thirds {
		width: 100%;
	}

	.column.signoff {
		width: 100%;
		text-align: center;
	}

	.column.full {
		width: 100%;
	}

	.column.half {
		width: 100%;
	}

	.column.half._1 {
		display: block;
		height: 50%;
		padding: 1.25rem 1.5rem;
	}

	.column.half._2 {
		display: block;
		padding: 1.25rem 1.5rem;
	}

	.column.image-half {
		width: 100%;
	}

	.column.image-half._1 {
		display: block;
		height: 50%;
		padding: 1.25rem 1.5rem;
	}

	.column.image-half._2 {
		display: block;
		padding: 1.25rem 1.5rem;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.column.image-half {
		width: 100%;
	}

	.column.image-half._1 {
		display: block;
		height: 50%;
		padding: 1.25rem 1.5rem;
	}

	.column.image-half._2 {
		display: block;
		padding: 1.25rem 1.5rem;
	}

	.logo-image {
		margin-top: 0.3rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.menu-button {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.copyright-text {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.footer-column-wrap {
		width: 100%;
		max-width: 100%;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.footer-column-wrap._2 {
		width: 50%;
		padding-bottom: 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.footer-column-wrap._3 {
		width: 50%;
		padding-bottom: 0rem;
	}

	.header-contents-wrap {
		height: auto;
	}

	.form-field.header-search-field {
		height: 3.5rem;
	}

	.secondary-nav-list {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.section-wrap.inside-content-section {
		padding-top: 7rem;
	}

	.secondary-nav-icon {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.secondary-nav-list-link {
		padding-right: 1em;
		padding-left: 1em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		text-align: center;
	}

	.slider {
		height: 675px;
	}

	.slider-arrow {
		top: 11rem;
		width: 4rem;
		height: 225px;
		margin-top: 0px;
		margin-bottom: 0px;
		opacity: 0.65;
	}

	.slider-indicator {
		margin-top: -2rem;
		padding-top: 1.1rem;
		font-size: 0.6rem;
	}

	.home-content-box-wrap.products {
		width: 100%;
		margin-bottom: 0rem;
		padding-bottom: 0rem;
	}

	.home-content-box-wrap.contract {
		width: 100%;
	}

	.slider-image {
		position: relative;
		height: 300px;
	}

	.slider-image._1 {
		top: 100px;
		height: 400px;
		background-repeat: no-repeat;
	}

	.slider-image._2 {
		height: 400px;
		background-position: 50% -96px;
		background-size: 1000px;
		background-repeat: no-repeat;
	}

	.slider-image._3 {
		height: 400px;
		background-position: 50% -96px;
		background-size: 1000px;
		background-repeat: no-repeat;
	}

	.slider-caption-wrap {
		top: 60%;
		height: 203px;
		min-width: auto;
		margin-right: 0%;
		margin-bottom: 0rem;
		margin-left: 0%;
		padding: 1rem 1.5rem 0rem;
		background-color: #f4f6f1;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.inside-page-header-content-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.main-content-wrap.inside-left {
		margin-right: 0px;
		margin-left: 0px;
	}

	.main-content-wrap.left-right {
		margin-right: 0px;
		margin-left: 0px;
	}

	.main-content-wrap.inside-right {
		margin-right: 0px;
		margin-left: 0px;
	}

	.main-content-wrap.full {
		margin-right: 0px;
		margin-left: 0px;
	}

	.main-content-wrap.styles {
		margin-right: 0px;
		margin-left: 0px;
	}

	.menu-button-icon {
		padding-top: 1.85rem;
		padding-bottom: 1.85rem;
		line-height: 3rem;
	}

	.intro-text-style {
		font-size: 1.125em;
	}

	.home-cbox-text-wrap {
		min-width: 100%;
		float: none;
	}

	.slider-arrow-icon {
		top: 65%;
	}

	.wide-background-block.content-box-background {
		padding-bottom: 0rem;
	}

	.slider-caption-title {
		font-size: 1.35rem;
		line-height: 1.85rem;
	}

	.pagination-middle-wrap {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.loc-type-form-checkbox {
		width: 50%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 50%;
	}

	.header-container {
		height: 100px;
		padding-top: 0rem;
		padding-left: 0rem;
	}

	.secondary-header-item-wrap {
		font-size: 1rem;
	}

	.header-content-right-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around;
	}

	.secondary-header-item-cta-link {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		padding-top: 0.75em;
		padding-right: 2.35em;
		padding-left: 1.35em;
		background-position: 90% 50%;
		background-size: 8px 8px;
		font-size: 0.6875rem;
		line-height: 1rem;
	}

	.header-search-wrap {
		margin-top: 0rem;
	}

	.slider-caption-title-wrap {
		width: 100%;
	}

	.secondary-header-item-icon {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.patient-portal-button {
		width: 121px;
		padding-right: 1.5rem;
		background-position: 94% 50%;
		font-size: 0.84rem;
	}

	.slide-caption-text {
		width: 80%;
		max-width: 525px;
		min-width: 400px;
	}

	.search-input {
		display: block;
		width: auto;
		height: 100%;
		min-height: 42px;
		min-width: 85%;
		padding-left: 1.5rem;
		background-color: #eee;
		color: #1b2f63;
	}

	.search-input:focus {
		background-color: #bef2ff;
		background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.75), hsla(0, 0%, 100%, 0.75));
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.75), hsla(0, 0%, 100%, 0.75));
	}

	.search-input::-webkit-input-placeholder {
		color: #1b2f63;
	}

	.search-input:-ms-input-placeholder {
		color: #1b2f63;
	}

	.search-input::placeholder {
		color: #1b2f63;
	}

	.search-button:hover {
		background-color: #3aa1af;
	}

	.chinese-button {
		margin-top: 0px;
		margin-bottom: 0.5rem;
		background-image: none;
		font-size: 0.84rem;
	}

	.search-form-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		min-width: 480px;
	}

	.search-field-div {
		width: 85%;
	}

	.search-button-hidden {
		width: 15%;
	}

	.search-wrap-new {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 1.5rem;
	}

	.navigation-section {
		height: 100px;
	}

	.logo-div {
		width: 50%;
	}

	.chinese-button-wrap {
		text-align: center;
	}

	.events-news-haeding {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
}

@media (max-width: 479px) {
	body {
		font-size: 0.9375rem;
		line-height: 1.5em;
	}

	h1 {
		font-size: 1.625rem;
	}

	h2 {
		font-size: 1.4375rem;
		line-height: 1.5em;
	}

	.button.header-search-button {
		width: 78px;
		min-height: 3rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.container.news {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.about-us {
		padding: 1rem 0rem;
	}

	.container.products-and-contracts {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.container.products-and-contracts._2 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.container.inside-right {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.styles {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.nav-link.two-part-mobile {
		width: 75%;
	}

	.nav-menu-wrap {
		width: 300px;
		min-width: auto;
	}

	.logo-nittobo-link {
		width: 250px;
		height: 91px;
		max-width: 100%;
		min-width: 100%;
		margin: 0rem auto;
		padding-left: 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.logo-nittobo-link.w--current {
		display: block;
		width: 250px;
		max-width: 100%;
		min-width: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		padding-top: 0.3rem;
		padding-right: 0px;
		padding-bottom: 0rem;
		float: left;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.row {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.row.footer {
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.column {
		width: 100%;
		float: none;
	}

	.column.in-thirds {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column.full {
		width: 100%;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column.half {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column.half._1 {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column.half._2 {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.column.image-half {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column.image-half._2 {
		min-height: 200px;
	}

	.column.image-half {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.column.image-half._1 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		min-height: 200px;
	}

	.column.footer {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-top: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.logo-image {
		display: block;
		width: 200px;
		min-width: 230px;
		margin-top: 5px;
		float: none;
	}

	.menu-button {
		min-height: auto;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-column-wrap {
		padding-right: 0rem;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.footer-column-wrap._2 {
		width: 100%;
	}

	.footer-column-wrap._3 {
		margin-bottom: 0rem;
	}

	.header-content-right-wrap {
		display: none;
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-menu-wrap {
		width: 30%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.form-field.header-search-field {
		height: 3rem;
		padding-right: 28%;
		padding-left: 1.25rem;
	}

	.secondary-nav-list {
		max-width: 75%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.section-wrap.inside-content-section {
		width: 100%;
		padding-top: 7rem;
	}

	.secondary-nav-text {
		display: none;
	}

	.slider {
		height: 530px;
		min-height: 300px;
	}

	.slider-arrow {
		top: 0rem;
		height: 50%;
		max-width: 50px;
		opacity: 0.75;
		font-size: 1.25rem;
	}

	.slider-indicator {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 2.5rem;
		padding-top: 0.9rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.home-content-box-wrap {
		margin-bottom: 1rem;
		padding-bottom: 0rem;
		float: none;
	}

	.home-content-box-title {
		font-size: 1.25em;
	}

	.slider-image {
		height: 230px;
	}

	.slider-image._1 {
		height: 250px;
		background-repeat: no-repeat;
	}

	.slider-image._2 {
		background-size: 1000px;
		background-repeat: no-repeat;
	}

	.slider-image._3 {
		background-size: 1000px;
		background-repeat: no-repeat;
	}

	.slider-caption-wrap {
		top: 55%;
		bottom: 0px;
		z-index: 3;
		min-width: auto;
		margin-bottom: 0rem;
		padding-top: 0rem;
		padding-right: 0%;
		padding-left: 0%;
		background-color: #f4f6f1;
	}

	.menu-button-icon {
		display: block;
		width: 100%;
		min-height: auto;
		margin-top: 0rem;
		padding-top: 1.85rem;
		padding-bottom: 1.85rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.menu-button-text {
		display: none;
	}

	.header-menu-inside-wrap {
		min-width: 60px;
	}

	.home-cbox-text-wrap {
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		float: none;
	}

	.home-cbox-text-wrap.news {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.home-cbox-text-wrap.about-us {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slider-arrow-icon {
		top: 72%;
		font-size: 1.5rem;
	}

	.wide-background-block.content-box-background {
		padding-bottom: 0rem;
	}

	.slider-caption-title {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.footer-links-list {
		margin-bottom: 0rem;
	}

	.link.content-box-title-link {
		font-size: 1.25rem;
		line-height: 2.15rem;
	}

	.pagination-middle-wrap {
		min-width: 100%;
		margin-top: 0.75em;
		padding-top: 0.75em;
		border-top: 1px solid rgba(84, 159, 196, 0.25);
	}

	.loc-type-form-checkbox {
		width: 100%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 100%;
	}

	.nav-drop-toggle-link {
		width: 84%;
		background-position: 95% 50%;
	}

	.two-part-button-toggle {
		width: 25%;
		line-height: 1.625rem;
	}

	.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100px;
		padding-left: 1.25rem;
	}

	.slider-caption-title-wrap {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.secondary-header-item-text {
		display: none;
	}

	.footer-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-wrapper {
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.main-content-wrapper.inside-left {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.main-content-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.main-content-container.left-right {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.patient-portal-button.mobile {
		width: 136px;
		margin-top: 0rem;
		margin-right: 4rem;
		margin-bottom: 0rem;
		border-color: #54c4c4;
		background-color: transparent;
		background-image: url('/20170713122722/assets/images/patient_portal_secure_icon_hover-07.svg');
		background-position: 91% 50%;
		background-size: 14px;
		color: #277f8b;
		line-height: 1.05rem;
		font-weight: 600;
	}

	.patient-portal-button.mobile:hover {
		border-color: transparent;
		background-color: #176a99;
		background-image: url('/20170713122722/assets/images/patient_portal_secure_icon.svg');
		background-position: 91% 50%;
		background-size: 14px;
		color: #fff;
	}

	.slide-caption-text {
		width: 100%;
		max-width: none;
		min-width: auto;
		font-size: 0.85rem;
		line-height: 1.25rem;
		text-align: left;
	}

	.search-input {
		min-height: 45px;
		min-width: 81%;
		background-color: #eee;
	}

	.search-button {
		width: 8%;
		height: 40px;
		max-width: 45px;
		min-width: 30px;
	}

	.search-button:hover {
		background-color: #3aa1af;
	}

	.chinese-button {
		width: 100%;
		height: 40px;
		min-width: auto;
		padding: 0.5rem 0.25rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		font-size: 0.725rem;
		font-weight: 700;
		text-transform: uppercase;
	}

	.chinese-button:hover {
		background-color: transparent;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		color: #196db6;
	}

	.search-form-wrapper {
		height: 42px;
		min-width: 320px;
	}

	.search-field-div {
		width: 83%;
		min-height: 40px;
		padding-left: 1.25rem;
	}

	.search-button-hidden {
		width: 12%;
		min-height: 40px;
		min-width: 85px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.link-arrow {
		width: 15%;
	}

	.navigation-section {
		height: 100px;
	}

	.logo-div {
		width: 70%;
	}

	.chinese-button-wrap {
		top: 0px;
		right: 0px;
		width: 6rem;
		min-width: 3rem;
	}

	.events-news-haeding {
		padding: 1.5rem 0rem;
	}
}

/************* Webflow styles ***********************/
/****************************************************/
html {
	font-size: 16px;
}
/* Set font render */
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}
/* Superscript */
sup {
	font-size: xx-small;
}
/* Clear input field styling */
input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
/* COLLAPSIBLE STICKY HEADER  */
@media screen and (min-width: 992px) {
	.sticky.navigation-section {
		height: 85px;
		transition: all 400ms ease-in-out;
	}

	.sticky .logo-image {
		width: 200px;
		transition: all 400ms ease-in-out;
	}

	.sticky .header-contents-wrap {
		height: 85px;
		background-color: white;
		transition: all 400ms ease-in-out;
	}

	.sticky .header-container {
		display: flex;
		align-self: center;
		transition: all 400ms ease-in-out;
	}
}
/* --- Style Nav Slider Dots --- */
/* .w-num .w-round or omit for square*/
.w-slider-nav.w-round > div {
	background-color: rgba(255, 255, 255, 0.85);
}

.w-slider-dot.w-active {
	background-color: #196db6 !important;
}
/* Footer phone number auto-styling on mobile devices */
.footer-section a[href^=tel] {
	-webkit-transition: all 250ms ease;
	text-decoration: none;
	color: inherit;
}
/* Adjust heading styles when they're set as links */
xh1 > a {
	color: inherit;
}

xh1 > a:hover {
	color: inherit;
}
/* Text selection style for Mozilla based browsers */
::-moz-selection {
	background-color: #e3e3e3;
	color: #000;
}
/* Text seclection style for Safari browser */
::selection {
	background-color: #e3e3e3;
	color: #000;
}

x.w-slider-nav > div {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div:hover {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div.w-active {
	border: white 3px solid;
	background: white;
}

x.w-slider-nav > div.w-active:hover {
	border: white 3px solid;
	background: white;
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.header-container:before,
.header-container:after {
	display: none;
}

.mobile-menu-button:after,
.mobile-menu-button:before {
	display: none;
}

.header-contents-wrap .chinese-button-wrap a {
	border-bottom: 0px;
}

.nav-drop-wrap.active .nav-drop-toggle {
	border-bottom-width: 4px;
	border-bottom-color: #196db6;
	background-color: hsla(0, 0%, 100%, 0.9);
	background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, #fff, hsla(191, 6%, 61%, 0.08) 99%);
	background-image: radial-gradient(circle farthest-corner at 50% 50%, #fff, hsla(191, 6%, 61%, 0.08) 99%);
	color: hsla(0, 0%, 65%, 0.8);
}

.nav-drop-wrap.active .nav-drop-toggle .nav-drop-toggle-link {
	border-bottom-color: #196db6;
	background-color: transparent;
	color: #00214b;
}

.nav-drop-list {
	display: block;
	height: 0px;
	opacity: 0;
	transition: opacity 200ms ease 0s;
	top: 100%;
	left: 0;
}

.nav-dropdown-container {
	display: none;
}

.nav-drop-wrap:hover .nav-drop-list,
.nav-drop-wrap.open .nav-drop-list {
	opacity: 1;
	height: auto;
}

.nav-drop-wrap.open .nav-drop-list .nav-dropdown-container {
	display: block;
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background: #002d54;
}

.mobile-navigation-menu li .w-nav-link {
	padding: 0.75rem 1.15rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 400;
	text-align: left;
	font-size: 0.85rem;
	line-height: 1.25rem;
}

.mobile-navigation-menu li .w-nav-link:hover,
.mobile-navigation-menu li.active .w-nav-link {
	background-color: #0054a6 !important;
	box-shadow: inset 4px 0 0 0 #196db6;
	color: #fff;
}

.mm-listitem,
.mm-listitem .mm-btn_next,
.mm-listitem .mm-btn_next:before,
.mm-listitem:after,
.mm-listview,
.mm-menu .mm-navbar {
	border-color: rgba(140, 205, 214, 0.1);
}

.mm-listitem:after {
	left: 0px;
}

.mm-listitem .mm-btn_next {
	background-image: url("/20170713122722/assets/images/mobile-arrow.svg");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 17px 18px;
}

.mm-listitem .mm-btn_next:hover {
	background-color: #0054a6;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: transparent;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 400;
	text-align: left;
	font-size: 0.85rem;
	line-height: 1.25rem;
	text-align: left;
}

.mm-menu .mm-navbar {
	height: 45px;
}

.mm-menu .mm-navbar a.mm-btn_prev {
	background-image: url("/20170713122722/assets/images/mobile-arrow-back.png");
	background-repeat: no-repeat;
	background-position: 52% 51%;
	background-size: 15px;
}

.mm-menu .mm-btn_prev:before {
	border-color: transparent;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -15px;
}

.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
.mm-menu .mm-listitem_selected > span {
	background-color: transparent;
}

.mobile-navigation-menu a {
	border-bottom: 0px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.mm-wrapper_opened .navigation-section {
		display: none;
	}
}

@media (max-width: 767px) {
	.mm-listitem .mm-btn_next {
		background-size: 12px 12px;
	}

	.mm-menu .mm-navbar a.mm-btn_prev {
		background-size: 12px 12px;
	}
}

/******************** Homepage **********************/
/*****************************************************/
.home-content-box-title {
	color: #196db6;
	font-weight: 600;
	margin-top: 0rem;
	margin-bottom: 0.65rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
	font-size: 1.5em;
	line-height: 1.5em;
}

.home-content-box-title a {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
	border-bottom: 1px solid transparent;
}

.home-content-box-title a:hover {
	border-bottom: 1px solid rgba(139, 184, 222, 0.65);
	color: #799165;
}

.home-content-box-title.white {
	font-weight: 600;
	color: #fff;
	margin-top: 0rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
	margin-top: 0rem;
	margin-bottom: 0.65rem;
	font-size: 1.5em;
	line-height: 1.5em;
}

.home-content-box-title.white a {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
	border-bottom: 2px solid transparent;
}

.home-content-box-title.white a:hover {
	border-bottom-width: 2px;
	border-bottom-color: hsla(0, 0%, 100%, 0.25);
}

.contracts-paragraph * {
	color: #fff;
}

.products-paragraph * {
	color: #fff;
}

h2.events-news-haeding {
	font-size: 2.25rem;
	line-height: 2.9rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
	margin-top: 0rem;
	margin-bottom: 0.5rem;
}

.events-news-haeding a,
.events-news-haeding a:hover {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
	border-bottom: 0px;
}

#news_module.homepage_contentbox .content-box-title-link,
#events_module.homepage_contentbox .content-box-title-link {
	margin: 0px;
}

#news_module.homepage_contentbox .cms_description,
#events_module.homepage_contentbox .cms_description {
	color: #444;
}

#news_module.homepage_contentbox:before,
#news_module.homepage_contentbox:after,
#events_module.homepage_contentbox:before,
#events_module.homepage_contentbox:after {
	display: none;
}

@media (max-width: 991px) {
	h2.events-news-haeding {
		font-size: 2rem;
	}
}

@media (max-width: 767px) {
	h2.events-news-haeding {
		font-size: 1.6875rem;
		line-height: 1.3125em;
	}
}

@media (max-width: 479px) {
	h2.events-news-haeding {
		font-size: 1.625rem;
	}
}

/********************* Slideshow **************************/
/**********************************************************/
.slide-caption-text p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
}

.slide-caption-text p:last-child {
	margin-bottom: 0px;
}

/****************** Inside Page *******************/
/*************************************************/
.breadcrumb-list .breadcrumb-list-item:last-child .link.breadcrumb-link {
	color: #444;
	border-bottom: 0px;
}

.breadcrumb-list .breadcrumb-list-item:last-child .breadcrumb-divider {
	display: none;
}

h2.left-nav-list-link {
	margin: 0px;
}

h2.left-nav-list-link a,
h2.left-nav-list-link a:hover {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-size: inherit;
	font-family: inherit;
	border-bottom: 0px;
}

.left-nav-nested-list-link.active,
.left-nav-list-link.active,
.left-nav-nested-list-link.active:hover,
.left-nav-list-link.active:hover {
	background-color: #fff;
	background-image: url("/20170713122722/assets/images/nav-arrow_blue-01.svg");
	background-size: 11px 11px;
	color: #0054a6;
}

.no-columns .main-content-column {
	padding-left: 0px;
	padding-right: 0px;
}

.right-column .main-content-column {
	padding-left: 0px;
	padding-right: 0px;
}

.right-column .right-sidebar-column {
	padding-left: 10px;
	padding-right: 10px;
}

td img {
	max-width: none;
}

@media (max-width: 991px) {

	.right-column .right-sidebar-column {
		padding-left: 0px;
		padding-right: 0px;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/******************* Right Sidebar *******************/
/*****************************************************/
.right-sidebar-column .box {
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5rem;
	margin-bottom: 2.441em;
}


.right-sidebar-column .box h4,
.right-sidebar-column .box h3 {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.775rem;
	text-transform: none;
	color: #0054a6;
	font-weight: 600;
	letter-spacing: 0.01rem;
}


.right-sidebar-column .box p {
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.right-sidebar-column .box ul {
	font-size: 0.875rem;
	line-height: 1.5rem;
}


.right-sidebar-column .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box.links p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.right-sidebar-column .box.tools td:first-child img {
	margin-right: 5px;
}

@media (max-width: 991px) {

	.right-sidebar-column .box {
		margin-left: 0px;
	}
}


/*********************** Footer **********************/
/*****************************************************/
.row.footer:before,
.row.footer:after {
	display: none;
}


.footer-section a {
	border-bottom: 0px;
}

#GRIPFooterLogo {
	line-height: 6px;
	float: right;
	padding-top: 0px !important;
}

.footer-contact-label .desktop {
	display: inline;
}

.footer-contact-label .mobile {
	display: none;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
}

.footer-link.footer-list-item-link.active {
	color: #bef2ff;
}

@media (max-width: 991px) {
	.footer-contact-label .desktop {
		display: none;
	}

	.footer-contact-label .mobile {
		display: inline;
	}
}

@media (max-width: 479px) {
	#GRIPFooterLogo {
		float: none;
		margin: 0 auto;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
body a {
	border-bottom: 1px solid rgba(0, 185, 242, 0.25);
	color: #0054a6;
	font-size: 1rem;
}

body a:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #196db6;
}

body h1 a,
body h2 a,
body h3 a,
body h4 a,
body h5 a,
body h6 a {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #196db6;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
}

body h1 a:hover,
body h2 a:hover,
body h3 a:hover,
body h4 a:hover,
body h5 a:hover,
body h6 a:hover {
	color: #0054a6;
	border-bottom: 2px solid rgba(0, 185, 242, 0.15);
}

/* buttons */
.inside-body-section .button,
.primary,
.inside-body-section button[type="submit"],
.inside-body-section .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
button[type=submit] {
	padding: 0.5rem 1.5rem;
	border: 1px solid #196db6;
	background-color: #196db6;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.primary:hover,
.inside-body-section button[type="submit"]:hover,
.inside-body-section .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
button[type=submit]:hover {
	border-color: rgba(0, 84, 166, 0.15);
	background-color: #fff;
	color: #0054a6;
}

.secondary,
.inside-body-section button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-body-section .cms_form_button.secondary,
.inside-body-section .button.secondary {
	padding: 0.5rem 1.5rem;
	border: 1px solid #0054a6;
	background-color: #0054a6;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.secondary:hover,
.inside-body-section button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-body-section .cms_form_button.secondary:hover,
.inside-body-section .button.secondary:hover {
	border-color: rgba(0, 84, 166, 0.15);
	background-color: #fff;
	color: #0054a6;
}

.tertiary,
.inside-body-section button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 0.5rem 1rem;
	border: 2px solid rgba(0, 84, 166, 0.15);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	background-color: #fff;
	color: #196db6;
	font-size: 0.85rem;
	line-height: 1.5em;
}

.tertiary:hover,
.inside-body-section button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	border-color: transparent;
	background-color: #004180;
	color: #fff;
}

/* messages */
#message.success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #079429;
	color: #fff;
}

#message.error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #c23737;
	color: #fff;
}

#message.success *,
#message.error * {
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #0054a6;
	color: white;
	font-weight: normal;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #196db6;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: none;
	font-family: 'Work Sans', sans-serif;
	box-shadow: none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: rgba(0, 0, 0, 0.05);
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="file"],
select,
textarea {
	padding: 4px 10px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

textarea {
	resize: vertical;
}

label {
	line-height: 1.25rem;
	font-weight: 700;
}

@media (max-width: 991px) {
	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	input[type="search"],
	input[type="file"],
	select,
	textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left-color: #196db6;
	background-color: #f8f8f8;
	color: #0054a6;
	font-size: 1.15rem;
	line-height: 1.85rem;
	font-weight: 600;
}

blockquote p {
	margin-top: 0;
	margin-bottom: 0;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

/* calendar */
#ui-datepicker-div {
	font-size: 0.9rem;
}

/******************** Modules **********************/
/***************************************************/
/* events */
#events_module #fullcalendar-controls #categories label,
#events_module.cms_list .location-label-events-module {
	font-weight: 400;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 10px;
}

#events_module.cms_list .cms_list_item .cms_date {
	font-weight: 400;
	font-size: 1rem;
	margin-top: 4px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_label {
	font-weight: 700;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

#events_module.cms_list .cms_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_form .cms_field .cms_td_answers label {
	font-weight: 400;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}

/* news */
#news_module.homepage_contentbox,
#events_module.homepage_contentbox {
	display: flex;
	width: 100%;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list article time {
	font-weight: 400;
}

#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 0.9rem;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}

	#news_module.homepage_contentbox,
	#events_module.homepage_contentbox {
		padding-bottom: 0;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-right: 0;
		padding-left: 0;
	}
}

/* products */
#products_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#products_module.cms_entity .cms_content .image {
	margin-right: 15px;
	margin-left: 0px;
}

#products_module.cms_list .cms_item_divider,
#products_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}

@media (max-width: 991px) {
	#products_module.cms_list .cms_list_item.left,
	#products_module.cms_list .cms_list_item.right {
		width: 100%;
		display: block;
		padding-left: 0px;
		padding-right: 0px;
	}

	#products_module.cms_list .cms_list_item .tile_description .image {
		float: none;
		display: block;
		padding-left: 0px;
	}

	#products_module.cms_list .cms_list_item .cms_title {
		padding: 0px;
	}

	#products_module.cms_list .cms_list_item.left tr td,
	#products_module.cms_list .cms_list_item.right tr td {
		border-bottom: 1px solid #e1e3e0;
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	#products_module.cms_list .cms_item_divider {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		background: none !important;
	}
}

@media (max-width: 767px) {
	#products_module.cms_entity .cms_content .image {
		display: inline-block;
		float: none;
		margin: 0px 0px 10px 0px;
	}
}

/* submission form */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: 600;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .cms_label label,
#submissionforms_module.cms_form .cms_label {
	font-weight: 700;
}

#submissionforms_module.cms_form .cms_field .checkbox_wrapper label,
#submissionforms_module.cms_form .cms_field .radio_wrapper label {
	font-weight: 400;
}

/***************** Content Boxes *******************/
/***************************************************/
/* events */
#events_module.homepage_contentbox {
	width: 100%;
}


/* form */
#submissionforms_module.homepage_contentbox table {
	width: auto;
}

.slider-section #submissionforms_module.homepage_contentbox {
	padding: 150px 20px 20px 20px;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

.home-cbox-text-wrap.about-us #submissionforms_module.homepage_contentbox,
.news-full-div #submissionforms_module.homepage_contentbox {
	color: #444;
}


@media (min-width: 991px) {
	.slider-section #submissionforms_module.homepage_contentbox table td,
	.slider-section #submissionforms_module.homepage_contentbox table a,
	.slider-section #submissionforms_module.homepage_contentbox table a:hover,
	.slider-section #submissionforms_module.homepage_contentbox .cms_hint,
	.slider-section #submissionforms_module.homepage_contentbox .cms_date_hint {
		color: #fff;
		font-weight: normal;
	}
}
